import React from "react"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/about"
import { urls } from "../components/languages"
import { section } from "./index.module.scss"

const IndexPage = () => {
    const currentLang = "pl"
    if (typeof window !== "undefined") {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    return (
        <PageTransition>
            <Layout lang={currentLang} bodyColor={'black'}>
                <Seo
                    lang={currentLang}
                    title="Strona główna"
                    keywords={[`powszuk`, `design`]}
                />
                <section id={urls[currentLang].about.section} className={`section ` + section}>
                    <About lang={currentLang} />
                </section>
            </Layout>
        </PageTransition>
    )
}

export default IndexPage
